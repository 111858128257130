const Cards = ({ data, label, onChange, defaultSelection }) => {
  return (
    <div>
      {label && (
        <div className="flex justify-between">
          <h3 className="text-sm font-medium text-gray-900">{label}</h3>
        </div>
      )}

      <div className="mt-4">
        <div className="flex flex-col sm:flex-row gap-3">
          {data.map((item) => (
            <div
              key={item.id}
              onClick={() => onChange(item)}
              className={`${
                item.id === defaultSelection
                  ? "bg-white border-indigo-500 ring-2 ring-indigo-500"
                  : "bg-white border-gray-300 hover:border-indigo-500"
              } relative flex flex-col border rounded-lg p-3 cursor-pointer focus:outline-none sm:flex-1 text-center items-center justify-center transition-all duration-200`}
            >
              <span className="text-sm font-medium text-gray-900">
                {item.friendly_name || item.value}
              </span>
              {item.description && (
                <span className="mt-1 text-sm text-gray-500">
                  {item.description}
                </span>
              )}
              {item.base_price > 0 && (
                <span className="mt-1 text-sm font-medium text-gray-900">
                  +£{item.base_price}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cards;
