import { supabase } from "../supabase"; // Import your Supabase client

// Function to fetch items from a specific table and column with an optional where clause
export const fetchData = async (
  tableName,
  columnName,
  whereClause = {},
  range,
  single,
  filters = {}
) => {
  let query = supabase.from(tableName).select(columnName);

  // Apply where clause if provided
  if (Object.keys(whereClause).length !== 0) {
    Object.entries(whereClause).forEach(([key, value]) => {
      if (key === 'or' && Array.isArray(value)) {
        // Handle OR conditions
        query = query.or(
          value.map(condition => 
            Object.entries(condition)
              .map(([k, v]) => {
                if (Array.isArray(v)) {
                  // For array fields like recommended_room and keywords
                  return `${k}.cs.{${v.join(',')}}`
                } else {
                  return `${k}.eq.${v}`
                }
              })
              .join(',')
          ).join(',')
        );
      } else if (value && typeof value === 'object' && 'neq' in value) {
        // Handle not equals
        query = query.neq(key, value.neq);
      } else if (Array.isArray(value)) {
        // Handle array contains
        query = query.contains(key, value);
      } else if (key === 'recommended_room') {
        // Use contains for array search
        query = query.contains(key, [value]);
      } else {
        query = query.eq(key, value);
      }
    });
  }

  if (range !== undefined && Object.keys(range).length !== 0) {
    query = query
      .order("created_at", { ascending: false })
      .range(range.start, range.end);
  }

  if (single) {
    query = query.limit(1).single();
  }
  
  let { data, error } = await query;

  if (error) {
    console.error("Error fetching data:", error);
    return error;
  } else {        
    return data;
  }
};
