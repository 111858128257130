import { useEffect, useState, useMemo } from "react";
import { fetchData } from "../../utility/fetchData";
import Button from "../Button.js";
import { motion } from "framer-motion";
import { getColorName } from '../../utility/colorNaming';

const ArtGallery = (props) => {
  const { 
    className, 
    userId, 
    resultsPerPage = 10, 
    filters = {}, 
    featured = false,
    relatedTo = null,
    compact = false 
  } = props;
  
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const defaultClassName = compact 
    ? "grid grid-cols-2 md:grid-cols-4 gap-4" 
    : "columns-1 md:columns-3 gap-4";
  const classNames = `${defaultClassName} ${className || ""}`;

  // Memoize the filters object to prevent unnecessary re-renders
  const memoizedFilters = useMemo(() => {
    // Start with base filters
    let queryFilters = {
      image_uploaded: true,
      status: 3
    };

    // Add user filters
    if (userId !== undefined) {
      queryFilters.user_id = userId;
    }

    if (featured) {
      queryFilters.featured = featured;
    }

    // Add any custom filters passed in
    queryFilters = { ...queryFilters, ...filters };

    // Special handling for related prints
    if (relatedTo) {
      // Create an array of conditions for similar items
      const conditions = [];
      
      if (relatedTo.color_category) {
        conditions.push({
          color_category: relatedTo.color_category
        });
      }
      
      if (relatedTo.recommended_room && Array.isArray(relatedTo.recommended_room)) {
        conditions.push({
          recommended_room: relatedTo.recommended_room
        });
      }
      
      if (relatedTo.color_psychology_primary_trait) {
        conditions.push({
          color_psychology_primary_trait: relatedTo.color_psychology_primary_trait
        });
      }
      
      if (relatedTo.keywords && Array.isArray(relatedTo.keywords)) {
        conditions.push({
          keywords: relatedTo.keywords
        });
      }

      if (conditions.length > 0) {
        queryFilters = {
          ...queryFilters,
          id: { neq: relatedTo.id },
          or: conditions
        };
      }
    }

    return queryFilters;
  }, [JSON.stringify(filters), userId, featured, relatedTo]);

  useEffect(() => {
    const loadImages = async () => {
      if (loading) return;
      setLoading(true);

      try {
        const startIndex = (currentPage - 1) * resultsPerPage;
        const endIndex = startIndex + resultsPerPage - 1;

        let queryFilters = { ...memoizedFilters };
        let colorMatchValue = null;

        // Handle color filter specially
        if (filters.color_palette?.type === 'color_match') {
          colorMatchValue = filters.color_palette.value;
          delete queryFilters.color_palette;
        }

        const response = await fetchData(
          "generation_jobs",
          `
            id, 
            image_paths, 
            title, 
            color_palette, 
            color_category, 
            recommended_room, 
            color_psychology_primary_trait,
            color_psychology_impact_level,
            description,
            revised_prompt
          `,
          queryFilters,
          { start: startIndex, end: endIndex }
        );

        if (response && response.length > 0) {
          // Filter results to only include images that have the matching color
          const filterResponse = response.filter(item => {
            if (!colorMatchValue) return true;
            
            return item.color_palette?.some(color => {
              if (!Array.isArray(color) || color.length !== 3) return false;
              const colorName = getColorName(color.map(Number));
              return colorName.toLowerCase() === colorMatchValue.toLowerCase();
            }) || false;
          });

          setImages((prevImages) => 
            currentPage === 1 
              ? filterResponse
              : [...prevImages, ...filterResponse]
          );
          setHasMore(filterResponse.length === resultsPerPage);
          setError(false);
        } else {
          setHasMore(false);
          if (currentPage === 1) {
            setImages([]);
          }
        }
      } catch (error) {
        setError(true);
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    loadImages();
  }, [currentPage, userId, resultsPerPage, memoizedFilters, featured]);

  const handleLoadMore = () => {
    if (!loading) setCurrentPage((prevPage) => prevPage + 1);
  };

  const generateSlug = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
  };

  const generatedImageItems = images.map((item, index) => (
    <div 
      key={index} 
      className={`${
        compact 
          ? "bg-white rounded-xl hover:shadow-lg transition-shadow duration-200" 
          : "p-2 bg-white rounded-xl hover:shadow-lg mb-4 break-inside-avoid-column"
      }`}
    >
      <a href={`/print/${generateSlug(item.title)}/${item.id}`} className="block">
        <motion.div
          className="relative"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.img
            whileHover={{ opacity: 0.8 }}
            transition={{ duration: 0.3 }}
            className="rounded-md w-full h-auto object-cover"
            src={item.image_paths[0]}
            alt={`${item.title} - Wall Art Print`}
            loading="lazy"
          />
        </motion.div>
        {item.title && (
          <div className={`p-2 ${compact ? 'text-sm' : ''}`}>
            <p className="text-slate-900 font-medium line-clamp-1">
              {item.title}
            </p>
            {compact && (
              <div className="mt-1 text-xs text-slate-500">
                {item.color_category && (
                  <span className="block">{item.color_category}</span>
                )}
                {item.recommended_room && (
                  <span className="block">
                    Perfect for: {Array.isArray(item.recommended_room) 
                      ? item.recommended_room[0] 
                      : item.recommended_room}
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </a>
    </div>
  ));

  if (error) {
    return <div>There was an error loading the images.</div>;
  }

  return (
    <div>
      <div className={classNames}>{generatedImageItems}</div>
      {hasMore && !loading && !compact && (
        <div className="my-8 mx-auto">
          <Button onClick={handleLoadMore}>Load more</Button>
        </div>
      )}
    </div>
  );
};

export default ArtGallery;
