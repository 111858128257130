import { useState, useEffect } from 'react';
import { supabase } from '../supabase';

const IMPACT_LEVELS = ["Overwhelming", "Strong", "Moderate", "Balanced", "Subtle"];
const PRIMARY_TRAITS = ["Intense", "Energetic", "Cheerful", "Balanced", "Serene", "Regal", "Neutral"];

const ROOM_GROUPS = {
  "Living Spaces": ["Living Room", "Family Room", "Great Room"],
  "Work Spaces": ["Home Office", "Study", "Library", "Workshop"],
  "Rest Spaces": ["Bedroom", "Master Suite", "Reading Nook", "Meditation Room"],
  "Creative Spaces": ["Art Studio", "Craft Room", "Creative Space"],
  "Social Spaces": ["Dining Room", "Kitchen", "Home Bar"],
  "Wellness Spaces": ["Home Gym", "Yoga Space", "Meditation Room"],
  "Utility Spaces": ["Bathroom", "Utility Room", "Laundry Room"],
  "Outdoor Living": ["Sunroom", "Garden Room", "Conservatory"],
};

export const useRelatedCollections = (collectionType, value) => {
  const [relatedCollections, setRelatedCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRelatedCollections = async () => {
      setLoading(true);
      try {
        // Skip if value is undefined/null
        if (!value || value.toLowerCase() === 'undefined') {
          setRelatedCollections([]);
          return;
        }

        let related = [];

        switch (collectionType) {
          case 'color_psychology_impact_level': {
            const currentIndex = IMPACT_LEVELS.indexOf(value);
            if (currentIndex !== -1) {
              // Get adjacent impact levels
              const prevLevel = currentIndex > 0 ? IMPACT_LEVELS[currentIndex - 1] : null;
              const nextLevel = currentIndex < IMPACT_LEVELS.length - 1 ? IMPACT_LEVELS[currentIndex + 1] : null;
              
              related = [
                ...(prevLevel ? [{
                  type: 'color_psychology_impact_level',
                  value: prevLevel,
                  label: `${prevLevel} Impact`
                }] : []),
                ...(nextLevel ? [{
                  type: 'color_psychology_impact_level',
                  value: nextLevel,
                  label: `${nextLevel} Impact`
                }] : [])
              ];

              // Also fetch traits commonly associated with this impact level
              const { data: traits } = await supabase
                .from('generation_jobs')
                .select('color_psychology_primary_trait')
                .eq('color_psychology_impact_level', value)
                .not('color_psychology_primary_trait', 'is', null)
                .not('color_psychology_primary_trait', 'eq', 'undefined')
                .limit(3);

              if (traits) {
                const uniqueTraits = [...new Set(traits.map(t => t.color_psychology_primary_trait))];
                related.push(...uniqueTraits.map(trait => ({
                  type: 'color_psychology_primary_trait',
                  value: trait,
                  label: `${trait} Prints`
                })));
              }
            }
            break;
          }

          case 'color_psychology_primary_trait': {
            const currentIndex = PRIMARY_TRAITS.indexOf(value);
            if (currentIndex !== -1) {
              // Get adjacent traits
              const prevTrait = currentIndex > 0 ? PRIMARY_TRAITS[currentIndex - 1] : null;
              const nextTrait = currentIndex < PRIMARY_TRAITS.length - 1 ? PRIMARY_TRAITS[currentIndex + 1] : null;
              
              related = [
                ...(prevTrait ? [{
                  type: 'color_psychology_primary_trait',
                  value: prevTrait,
                  label: `${prevTrait} Prints`
                }] : []),
                ...(nextTrait ? [{
                  type: 'color_psychology_primary_trait',
                  value: nextTrait,
                  label: `${nextTrait} Prints`
                }] : [])
              ];

              // Also fetch impact levels commonly associated with this trait
              const { data: impacts } = await supabase
                .from('generation_jobs')
                .select('color_psychology_impact_level')
                .eq('color_psychology_primary_trait', value)
                .not('color_psychology_impact_level', 'is', null)
                .not('color_psychology_impact_level', 'eq', 'undefined')
                .limit(2);

              if (impacts) {
                const uniqueImpacts = [...new Set(impacts.map(i => i.color_psychology_impact_level))];
                related.push(...uniqueImpacts.map(impact => ({
                  type: 'color_psychology_impact_level',
                  value: impact,
                  label: `${impact} Impact`
                })));
              }
            }
            break;
          }

          case 'recommended_room': {
            // Find the group this room belongs to
            const group = Object.entries(ROOM_GROUPS).find(([_, rooms]) => 
              rooms.includes(value)
            );

            if (group) {
              // Get other rooms from the same group
              const rooms = group[1];
              const relatedRooms = rooms.filter(room => room !== value).slice(0, 2);
              
              related = relatedRooms.map(room => ({
                type: 'recommended_room',
                value: room.replace(/\s+/g, '-').toLowerCase(),
                label: `${room} Prints`
              }));

              // Also fetch common traits for this room type
              const { data: traits } = await supabase
                .from('generation_jobs')
                .select('color_psychology_primary_trait')
                .contains('recommended_room', [value])
                .not('color_psychology_primary_trait', 'is', null)
                .not('color_psychology_primary_trait', 'eq', 'undefined')
                .limit(2);

              if (traits) {
                const uniqueTraits = [...new Set(traits.map(t => t.color_psychology_primary_trait))];
                related.push(...uniqueTraits.map(trait => ({
                  type: 'color_psychology_primary_trait',
                  value: trait,
                  label: `${trait} Prints`
                })));
              }
            }
            break;
          }

          case 'color_category': {
            // Fetch related colors based on psychology traits
            const { data: relatedColors } = await supabase
              .from('generation_jobs')
              .select('color_category, color_psychology_primary_trait')
              .eq('color_psychology_primary_trait', value)
              .not('color_category', 'is', null)
              .not('color_category', 'eq', 'undefined')
              .neq('color_category', value)
              .limit(3);

            if (relatedColors) {
              const uniqueColors = [...new Set(relatedColors.map(c => c.color_category))];
              related = uniqueColors.map(color => ({
                type: 'color_category',
                value: color,
                label: `${color} Prints`
              }));
            }

            // Also fetch common traits for this color
            const { data: traits } = await supabase
              .from('generation_jobs')
              .select('color_psychology_primary_trait')
              .eq('color_category', value)
              .not('color_psychology_primary_trait', 'is', null)
              .not('color_psychology_primary_trait', 'eq', 'undefined')
              .limit(2);

            if (traits) {
              const uniqueTraits = [...new Set(traits.map(t => t.color_psychology_primary_trait))];
              related.push(...uniqueTraits.map(trait => ({
                type: 'color_psychology_primary_trait',
                value: trait,
                label: `${trait} Prints`
              })));
            }
            break;
          }

          default: {
            // For any unhandled collection types, return empty array
            related = [];
            break;
          }
        }

        setRelatedCollections(related);
      } catch (err) {
        console.error('Error fetching related collections:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (collectionType && value) {
      fetchRelatedCollections();
    }
  }, [collectionType, value]);

  return { relatedCollections, loading, error };
}; 