import React, { useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ArtGallery from "../../components/ArtGallery/ArtGallery";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useCollectionMetadata } from "../../hooks/useCollectionMetadata";
import { useRelatedCollections } from "../../hooks/useRelatedCollections";
import { useCollectionsByType } from "../../hooks/useCollectionMetadata";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const Collections = () => {
  const { key, value } = useParams();
  const navigate = useNavigate();

  // Function to capitalize the first letter of each word
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Function to format recommended room value
  const formatRecommendedRoom = (str) => {
    return capitalizeWords(str.replace(/-/g, ' '));
  };

  // Function to properly format keyword for PostgreSQL
  const formatKeyword = (value) => {
    const formattedValue = decodeURIComponent(value)
      .toLowerCase()
      .replace(/"/g, '\\"');
    return `{${formattedValue}}`;
  };
  
  // Initialize hooks with null values when no parameters
  const formattedKey = key?.replace(/-/g, "_") || "";

  const formattedValue = key && value ? (
    formattedKey === 'recommended_room' 
      ? formatRecommendedRoom(decodeURIComponent(value))
      : formattedKey === 'color_psychology_primary_trait' || formattedKey === 'color_psychology_impact_level'
        ? capitalizeWords(decodeURIComponent(value))
        : decodeURIComponent(value)
  ) : "";

  // Fetch collections based on the route
  const { collections, loading: collectionsLoading } = useCollectionsByType(
    !key ? null : (value ? null : formattedKey)
  );

  // Only fetch metadata when we have both key and value
  const { metadata, loading: metadataLoading } = useCollectionMetadata(
    value ? formattedKey : null,
    value ? formattedValue : null
  );
  const { relatedCollections } = useRelatedCollections(
    value ? formattedKey : null,
    value ? formattedValue : null
  );

  // Generate breadcrumb items based on current route
  const getBreadcrumbItems = () => {
    const items = [];
    
    if (key) {
      items.push({
        label: capitalizeWords(key.replace(/-/g, ' ')),
        path: `/collections/${key}`
      });
    }
    
    if (value) {
      items.push({
        label: formattedValue,
        path: `/collections/${key}/${value}`
      });
    }
    
    return items;
  };

  // Check for invalid values
  useEffect(() => {
    if (key && value) {
      const decodedValue = decodeURIComponent(value);
      if (!decodedValue || 
          decodedValue.toLowerCase() === 'undefined' || 
          decodedValue.toLowerCase() === 'null') {
        navigate('/collections', { replace: true });
      }
    }
  }, [key, value, navigate]);

  // Handle root collections route
  if (!key) {
    if (collectionsLoading) {
      return <div>Loading...</div>;
    }

    // Group collections by type
    const groupedCollections = collections.reduce((acc, collection) => {
      if (!acc[collection.collection_type]) {
        acc[collection.collection_type] = [];
      }
      acc[collection.collection_type].push(collection);
      return acc;
    }, {});

    return (
      <div className="container mx-auto p-4 sm:p-8 max-w-7xl">
        <PageHeader 
          title="Art Collections" 
          className="text-3xl sm:text-5xl mb-8" 
        />
        
        {Object.entries(groupedCollections).map(([type, typeCollections]) => (
          <div key={type} className="mb-12">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold">
                {capitalizeWords(type.replace(/_/g, ' '))}
              </h2>
              <Link 
                to={`/collections/${type.replace(/_/g, '-')}`}
                className="text-blue-600 hover:text-blue-800 text-sm font-medium"
              >
                View all {capitalizeWords(type.replace(/_/g, ' '))} →
              </Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {typeCollections.slice(0, 4).map((collection) => (
                <Link 
                  key={collection.id}
                  to={`/collections/${type.replace(/_/g, '-')}/${collection.value.toLowerCase().replace(/\s+/g, '-')}`}
                  className="block p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
                >
                  <h3 className="text-xl font-semibold mb-2">{collection.title}</h3>
                  <p className="text-gray-600">{collection.description}</p>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Handle category-level collections route
  if (!value) {
    if (collectionsLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="container mx-auto p-4 sm:p-8 max-w-7xl">
        <Breadcrumbs items={getBreadcrumbItems()} />
        <PageHeader 
          title={`${capitalizeWords(key.replace(/-/g, ' '))} Collections`}
          className="text-3xl sm:text-5xl mb-4"
        />
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {collections.map((collection) => (
            <Link 
              key={collection.id}
              to={`/collections/${key}/${collection.value.toLowerCase().replace(/\s+/g, '-')}`}
              className="block p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
            >
              <h3 className="text-xl font-semibold mb-2">{collection.title}</h3>
              <p className="text-gray-600">{collection.description}</p>
            </Link>
          ))}
        </div>
      </div>
    );
  }

  // Handle collection item route
  const generateTitle = () => {
    const decodedValue = decodeURIComponent(value);
    switch (formattedKey) {
      case "keywords":
        return `${capitalizeWords(decodedValue)} Wall Art Prints`;
      case "color_category":
        return `${capitalizeWords(decodedValue)} Color Wall Art Prints`;
      case "color_psychology_impact_level":
        return `${capitalizeWords(decodedValue)} Psychological Impact Wall Art Prints`;
      case "color_psychology_primary_trait":
        return `${capitalizeWords(decodedValue)} Emotional Wall Art Prints`;
      case "recommended_room":
        return `Wall Art Prints for your ${formatRecommendedRoom(decodedValue)}`;
      case "color":
        return `${capitalizeWords(decodedValue)} Toned Wall Art Prints`;
      default:
        return "Wall Art Collection";
    }
  };

  const pageTitle = metadata?.title || generateTitle();

  const filterMapping = {
    keywords: "keywords",
    color: "color_palette",
    color_category: "color_category",
    color_psychology_impact_level: "color_psychology_impact_level",
    color_psychology_primary_trait: "color_psychology_primary_trait",
    recommended_room: "recommended_room",
  };

  const filters = filterMapping[formattedKey] 
    ? { 
        [filterMapping[formattedKey]]: formattedKey === 'keywords'
          ? formatKeyword(value)
          : formattedKey === 'color'
            ? { type: 'color_match', value: decodeURIComponent(value) }
          : formattedKey === 'recommended_room'
            ? formatRecommendedRoom(value)
            : capitalizeWords(decodeURIComponent(value))
      }
    : {};

  return (
    <>
      <Helmet>
        <title>{pageTitle} | Create Prints</title>
        {metadata && (
          <>
            <meta name="description" content={metadata.meta_description} />
            <meta name="keywords" content={metadata.seo_keywords?.join(', ')} />
            
            {/* Open Graph tags */}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={metadata.meta_description} />
            <meta property="og:type" content="website" />
            
            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={metadata.meta_description} />

            {/* Structured Data */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "CollectionPage",
                "name": pageTitle,
                "description": metadata.meta_description,
                "keywords": metadata.seo_keywords?.join(', '),
                "mainEntity": {
                  "@type": "ItemList",
                  "itemListElement": [
                    {
                      "@type": "ListItem",
                      "position": 1,
                      "name": `${formattedValue} Wall Art Collection`,
                      "description": metadata.description
                    }
                  ]
                },
                "about": {
                  "@type": "Thing",
                  "name": formattedValue,
                  ...(metadata.scientific_backing && {
                    "description": metadata.scientific_backing
                  }),
                  ...(metadata.psychological_effects && {
                    "additionalProperty": metadata.psychological_effects.map(effect => ({
                      "@type": "PropertyValue",
                      "name": "Psychological Effect",
                      "value": effect
                    }))
                  })
                },
                "offers": {
                  "@type": "AggregateOffer",
                  "priceCurrency": "GBP",
                  "availability": "https://schema.org/InStock"
                }
              })}
            </script>
          </>
        )}
      </Helmet>

      <div className="container mx-auto p-4 sm:p-8 max-w-7xl">
        <Breadcrumbs items={getBreadcrumbItems()} />
        
        {/* Title and Introduction Section */}
        <div className="mb-8">
          <PageHeader 
            title={pageTitle} 
            className="text-3xl sm:text-5xl mb-8" 
          />
          
          {metadata && (
            <p className="text-lg text-gray-600">
              {metadata.description}
            </p>
          )}
        </div>

        {/* Art Gallery Section - Full Width */}
        <div className="mb-12 -mx-4 sm:-mx-8 px-4 sm:px-8">
          <ArtGallery 
            className="columns-1 sm:columns-2 md:columns-3 lg:columns-4 xl:columns-5" 
            resultsPerPage={20} 
            filters={filters} 
          />
        </div>
        
        {/* Combined Metadata Section */}
        {metadata && (metadata.best_practices || metadata.room_tips || metadata.psychological_effects || metadata.scientific_backing) && (
          <div className="bg-white rounded-xl p-6 shadow-sm prose prose-lg max-w-none">
            <h2 className="text-2xl font-semibold mb-6">Guide to Choosing Wall Art</h2>
            
            {metadata.best_practices && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Best Practices</h3>
                <ul className="list-disc pl-5 space-y-2">
                  {Array.isArray(metadata.best_practices) 
                    ? metadata.best_practices.map((practice, index) => (
                        <li key={index} className="text-gray-700">{practice}</li>
                      ))
                    : Object.entries(metadata.best_practices).map(([key, value]) => (
                        <li key={key} className="text-gray-700">
                          <strong>{key}:</strong> {Array.isArray(value) ? value.join(', ') : value}
                        </li>
                      ))
                  }
                </ul>
              </div>
            )}

            {metadata.room_tips && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Room Tips</h3>
                {Object.entries(metadata.room_tips).map(([key, value]) => (
                  <div key={key} className="mb-4">
                    <h4 className="font-medium text-gray-900 mb-2">
                      {capitalizeWords(key.replace(/_/g, ' '))}
                    </h4>
                    {Array.isArray(value) ? (
                      <ul className="list-disc pl-5 space-y-1">
                        {value.map((tip, index) => (
                          <li key={index} className="text-gray-700">{tip}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-gray-700">{value}</p>
                    )}
                  </div>
                ))}
              </div>
            )}

            {metadata.psychological_effects && metadata.psychological_effects.length > 0 && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Psychological Effects</h3>
                <ul className="list-disc pl-5 space-y-2">
                  {metadata.psychological_effects.map((effect, index) => (
                    <li key={index} className="text-gray-700">{effect}</li>
                  ))}
                </ul>
              </div>
            )}

            {metadata.scientific_backing && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Scientific Background</h3>
                <p className="text-gray-700">{metadata.scientific_backing}</p>
              </div>
            )}
          </div>
        )}

        {/* Related Collections Section */}
        <div className="mt-12">
          <h2 className="text-2xl font-semibold mb-6">Related Collections</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {metadataLoading ? (
              // Loading state
              <>
                <div className="h-32 bg-gray-100 rounded-xl animate-pulse"></div>
                <div className="h-32 bg-gray-100 rounded-xl animate-pulse"></div>
                <div className="h-32 bg-gray-100 rounded-xl animate-pulse"></div>
              </>
            ) : relatedCollections.length > 0 ? (
              // Related collections grid
              relatedCollections.map((collection, index) => (
                <Link
                  key={index}
                  to={`/collections/${collection.type.replace(/_/g, '-')}/${encodeURIComponent(collection.value)}`}
                  className="block p-4 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    });
                  }}
                >
                  <h3 className="text-lg font-medium text-gray-900 mb-2">
                    {collection.label}
                  </h3>
                  <p className="text-sm text-gray-600">
                    {collection.type === 'color_psychology_impact_level' && 'Explore prints with similar psychological impact'}
                    {collection.type === 'color_psychology_primary_trait' && 'Discover prints with related emotional qualities'}
                    {collection.type === 'recommended_room' && 'Find prints for complementary spaces'}
                    {collection.type === 'color_category' && 'View prints in harmonious color categories'}
                  </p>
                </Link>
              ))
            ) : (
              // Empty state
              <div className="col-span-full text-center py-8">
                <p className="text-gray-500">No related collections found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collections;
