// AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { supabase } from "./supabase"; // Your Supabase initialization
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [createCredits, setCreateCredits] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const getUserCreateCredits = async (retryCount = 0) => {      
      if (session?.user?.id) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/get-create-credits-balance`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.access_token,
              },
            }
          );
          return response.data.generation_jobs_count;
        } catch (error) {
          if (retryCount < 3) {
            return await getUserCreateCredits(retryCount + 1);
          } else {
            console.error("Error fetching user credits after retries", error);
            return null;
          }
        }
      }
      return null;
    };

    if (session) {
      getUserCreateCredits().then((credits) => {
        setCreateCredits(credits);
      });
    }
  }, [session]);

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error);
    } else {
      setSession(null);
    }
  };

  return (
    <AuthContext.Provider value={{ session, supabase, signOut, createCredits }}>
      {children}
    </AuthContext.Provider>
  );
};
