import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthContext';
import { Link } from 'react-router-dom';

const ImageManagement = () => {
  const { supabase } = useContext(AuthContext);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImages, setSelectedImages] = useState(new Set());
  const [isPosting, setIsPosting] = useState(false);
  const [isPinterestAuthenticated, setIsPinterestAuthenticated] = useState(false);

  useEffect(() => {
    fetchImages();
    checkPinterestAuth();
  }, []);

  const fetchImages = async () => {
    try {
      const { data, error } = await supabase
        .from('generation_jobs')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setImages(data);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setLoading(false);
    }
  };

  const checkPinterestAuth = async () => {
    try {
      const { data, error } = await supabase
        .from('pinterest_auth')
        .select('expires_at')
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (!error && data && new Date(data.expires_at) > new Date()) {
        setIsPinterestAuthenticated(true);
      }
    } catch (error) {
      console.error('Error checking Pinterest auth:', error);
    }
  };

  const handlePinterestAuth = () => {
    const authUrl = `${process.env.REACT_APP_API_URL}/pinterest/auth`;
    console.log('Redirecting to Pinterest auth:', authUrl);
    window.location.href = authUrl;
  };

  const toggleSelection = (id) => {
    setSelectedImages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const setSelectedAsFeatured = async () => {
    try {
      const { error } = await supabase
        .from('generation_jobs')
        .update({ featured: true })
        .in('id', Array.from(selectedImages));

      if (error) throw error;
      
      // Update local state
      setImages(images.map(img => 
        selectedImages.has(img.id) ? { ...img, featured: true } : img
      ));
      // Clear selection
      setSelectedImages(new Set());
    } catch (error) {
      console.error('Error updating images:', error);
    }
  };

  const postSelectedToPinterest = async () => {
    setIsPosting(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/pinterest/post-images`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          imageIds: Array.from(selectedImages)
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Pinterest API Error:', {
          status: response.status,
          statusText: response.statusText,
          error: errorData
        });
        throw new Error(errorData.error || 'Failed to post to Pinterest');
      }

      const result = await response.json();

      // Update local state with Pinterest post information
      setImages(images.map(img => {
        if (selectedImages.has(img.id) && result[img.id]) {
          return {
            ...img,
            pinterest_posted: true,
            pinterest_post_url: result[img.id].url,
            pinterest_post_date: new Date().toISOString()
          };
        }
        return img;
      }));

      // Clear selection
      setSelectedImages(new Set());
    } catch (error) {
      console.error('Error posting to Pinterest:', error);
      alert('Failed to post to Pinterest. Please try again.');
    } finally {
      setIsPosting(false);
    }
  };

  const formatTitleForUrl = (title) => {
    return title ? title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '') : 'untitled';
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-6 relative pb-16">
      {selectedImages.size > 0 && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-10 flex space-x-4">
          <button
            onClick={setSelectedAsFeatured}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md shadow-lg hover:bg-indigo-700 transition-colors"
          >
            Set Selected as Featured
          </button>
          <button
            onClick={postSelectedToPinterest}
            disabled={isPosting}
            className={`bg-red-600 text-white px-4 py-2 rounded-md shadow-lg transition-colors ${
              isPosting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-700'
            }`}
          >
            {isPosting ? 'Posting...' : 'Post to Pinterest'}
          </button>
        </div>
      )}
      
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Generated Images</h1>
          <p className="mt-2 text-sm text-gray-700">
            Click on images to select them and set them as featured or post to Pinterest.
          </p>
        </div>
        {!isPinterestAuthenticated && (
          <div className="mt-4 sm:mt-0 sm:ml-16">
            <button
              onClick={handlePinterestAuth}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 0a12 12 0 0 0-4.373 23.178c-.017-1.403-.004-3.095.39-4.622l2.875-12.175c0-.073-.01-.145-.01-.22 0-1.454.843-2.54 1.89-2.54.89 0 1.32.668 1.32 1.47 0 .893-.567 2.232-.86 3.475-.246 1.033.52 1.88 1.544 1.88 1.852 0 3.102-2.38 3.102-5.198 0-2.143-1.445-3.743-4.072-3.743-2.968 0-4.815 2.213-4.815 4.685 0 .852.25 1.45.642 1.916.072.085.082.16.06.247l-.194.8c-.03.128-.1.154-.232.093-1.17-.536-1.714-1.97-1.714-3.585 0-2.666 2.25-5.86 6.72-5.86 3.587 0 5.944 2.597 5.944 5.383 0 3.684-2.05 6.44-5.074 6.44-1.015 0-1.968-.548-2.293-1.17l-.623 2.378c-.226.87-.835 1.958-1.244 2.62.937.29 1.93.446 2.96.446 5.523 0 10-4.477 10-10S17.523 0 12 0z"/>
              </svg>
              Connect Pinterest Account
            </button>
          </div>
        )}
      </div>
      
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {images.map((image) => (
          <div 
            key={image.id} 
            onClick={() => toggleSelection(image.id)}
            className={`bg-white overflow-hidden shadow rounded-lg cursor-pointer transition-all relative
              ${selectedImages.has(image.id) ? 'ring-2 ring-indigo-500' : 'hover:shadow-lg'}`}
          >
            <div className="relative">
              {image.image_paths && image.image_paths[0] && (
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={image.image_paths[0]}
                    alt={image.title || 'Generated artwork'}
                    className="object-cover"
                  />
                </div>
              )}
              <Link 
                to={`/print/${formatTitleForUrl(image.title)}/${image.id}`}
                onClick={(e) => e.stopPropagation()} // Prevent selection when clicking the link
                className="absolute top-2 right-2 p-1.5 bg-white bg-opacity-75 rounded-full hover:bg-opacity-100 transition-all"
                title="View print"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                </svg>
              </Link>
            </div>
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-center space-x-2">
                <div className="text-sm font-medium text-gray-900 truncate">
                  {image.title || 'Untitled'}
                </div>
                <div className="flex space-x-2">
                  {image.featured && (
                    <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                      Featured
                    </span>
                  )}
                  {image.pinterest_posted && (
                    <a
                      href={image.pinterest_post_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full flex items-center space-x-1 hover:bg-red-200"
                    >
                      <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M12 0a12 12 0 0 0-4.373 23.178c-.017-1.403-.004-3.095.39-4.622l2.875-12.175c0-.073-.01-.145-.01-.22 0-1.454.843-2.54 1.89-2.54.89 0 1.32.668 1.32 1.47 0 .893-.567 2.232-.86 3.475-.246 1.033.52 1.88 1.544 1.88 1.852 0 3.102-2.38 3.102-5.198 0-2.143-1.445-3.743-4.072-3.743-2.968 0-4.815 2.213-4.815 4.685 0 .852.25 1.45.642 1.916.072.085.082.16.06.247l-.194.8c-.03.128-.1.154-.232.093-1.17-.536-1.714-1.97-1.714-3.585 0-2.666 2.25-5.86 6.72-5.86 3.587 0 5.944 2.597 5.944 5.383 0 3.684-2.05 6.44-5.074 6.44-1.015 0-1.968-.548-2.293-1.17l-.623 2.378c-.226.87-.835 1.958-1.244 2.62.937.29 1.93.446 2.96.446 5.523 0 10-4.477 10-10S17.523 0 12 0z"/>
                      </svg>
                      <span>Posted</span>
                    </a>
                  )}
                </div>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                Created: {new Date(image.created_at).toLocaleDateString()}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageManagement; 