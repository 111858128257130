import { SparklesIcon } from "@heroicons/react/24/solid";
import {
  ArrowsPointingOutIcon,
  PhotoIcon,
  TruckIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import ArtGallery from "./components/ArtGallery/ArtGallery.js";
import Button from "./components/Button.js";

const Home = () => {
  return (
    <div>
      <div>
        <div className="mx-auto max-w-7xl pb-12 md:pb-24 pt-4 md:pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-20 overflow-hidden xl:overflow-visible">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <h1 className="text-5xl text-slate-900 font-semibold sm:text-7xl">
                  Create Unique
                  <br /> Art Prints
                </h1>
                <p className="mt-6 text-xl sm:text-2xl leading-8 text-slate-600">
                  Need art for your walls? Turn your own words or photos into
                  unique art prints, that magically match the style of your
                  interior.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Button
                    url="/generate-print"
                    className="flex items-center rounded-full bg-indigo-300 text-indigo-950 px-6 py-3 text-xl font-semibold enabled:hover:bg-indigo-200 transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-25 disabled:cursor-not-allowed justify-center"
                  >
                    <SparklesIcon className="h-4 w-4 mr-2" />
                    Create Your Art Print
                  </Button>
                </div>
                <div className="my-12">
                  <h3 className="text-2xl font-bold mb-6 text-slate-900">
                    Why you should choose Create Prints
                  </h3>
                  <ul>
                    <li className="flex flex-row mb-5">
                      <ArrowsPointingOutIcon className="h-6 w-6 mr-2" />{" "}
                      Flexible size options
                    </li>
                    <li className="flex flex-row mb-5">
                      <PhotoIcon className="h-6 w-6 mr-2" />
                      High definition print-ready prints
                    </li>
                    <li className="flex flex-row mb-5">
                      <TruckIcon className="h-6 w-6 mr-2" />
                      Printed and dispatched from the UK
                    </li>
                    <li className="flex flex-row mb-5">
                      <BanknotesIcon className="h-6 w-6 mr-2" />
                      30 Day money back guarantee
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 md:mt-20 mx-auto max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen relative">            
            <img
              src="https://nluobsmaflcaxqiawcvk.supabase.co/storage/v1/object/public/static/homepage/create-prints-hero-interior.jpeg"
              alt="Art Prints on a Wall"
              className="w-full h-auto rounded-2xl"
            />
          </div>
        </div>
      </div>
      <div className="bg-[#E5E5DC] py-12 sm:py-32">
        <div className="max-w-7xl mx-auto py-20">
          <div className="text-center px-6 py-6">
            <h2 className="text-4xl sm:text-6xl font-bold text-slate-900">
              How Does It Work?
            </h2>
            <p className="text-lg sm:text-xl text-slate-600 my-4">
              Create unique art prints for your home in three simple steps
            </p>
          </div>
          <div className="container mx-auto px-6 lg:px-0 mt-8">
            <div className="flex flex-wrap md:flex-nowrap">
              <div className="w-full md:w-1/2 flex justify-center items-center mb-6 md:mb-0">
                <div className="relative w-72 h-128 bg-black border-8 border-black p-1 rounded-3xl flex items-center justify-center">
                  <video controls autoPlay muted>
                    <source
                      src={
                        process.env.PUBLIC_URL +
                        "/create-prints-ai-how-it-works.mp4"
                      }
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className="w-full md:w-1/2 mt-12 md:mt-0">
                <div className="mb-8 md:mb-16 flex items-center flex-row md:items-start">
                  <img
                    src="https://nluobsmaflcaxqiawcvk.supabase.co/storage/v1/object/public/static/homepage/step-1.png"
                    className="mb-4 md:mb-0 mr-6 w-24 h-24"
                    alt="Step 1"
                  />
                  <div>
                    <h2 className="text-xl font-bold mb-2">
                      Choose the type and style
                    </h2>
                    <p className="text-base text-gray-600">
                      Choose from portrait, landscape or square and optionally
                      select the art style of the print you want to create.
                    </p>
                  </div>
                </div>
                <div className="mb-8 md:mb-16 flex items-center flex-row md:items-start">
                  <img
                    src="https://nluobsmaflcaxqiawcvk.supabase.co/storage/v1/object/public/static/homepage/step-2.png"
                    className="mb-4 md:mb-0 mr-6 w-24 h-24"
                    alt="Step 2"
                  />
                  <div>
                    <h2 className="text-xl font-bold mb-2 sm:mb-4">
                      Describe or upload
                    </h2>
                    <p className="text-base text-gray-600">
                      Describe your desired print in words or use a photo to
                      match an existing print or style of your interior.
                    </p>
                  </div>
                </div>
                <div className="flex items-center flex-row md:items-start">
                  <img
                    src="https://nluobsmaflcaxqiawcvk.supabase.co/storage/v1/object/public/static/homepage/step-3.png"
                    className="mb-4 md:mb-0 mr-6 w-24 h-24"
                    alt="Step 3"
                  />
                  <div>
                    <h2 className="text-xl font-bold mb-2 sm:mb-4">
                      Order your unique print
                    </h2>
                    <p className="text-base text-gray-600">
                      Now your print has been created, order the print with a
                      range of options including, size, frame and mount.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-w-7xl mx-auto md:py-20">
        <div className="sm:text-center px-6 py-12 lg:px-0">
          <h2 className="text-4xl sm:text-6xl font-bold text-slate-900">
            Featured Prints
          </h2>
          <p className="text-lg sm:text-xl text-slate-600 my-4">
            Browse prints generated by other users
          </p>
        </div>
        <div className="container mx-auto px-8">
          <ArtGallery className="sm:columns-3 md:columns-4" featured />
        </div>
      </div>
    </div>
  );
};

export default Home;
