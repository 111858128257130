// supabase.js
import { createClient } from '@supabase/supabase-js'

const options = {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce',
    passwordReset: {
      redirectTo: `${window.location.origin}/login`,
    },
    emailRedirectTo: `${window.location.origin}/login`,
  },
}

export const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY, options)
