import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { Helmet } from "react-helmet-async";
import Cards from "./components/Cards";
import { fetchData } from "./utility/fetchData";
import { useBasket } from "./basketContext";
import { AuthContext } from "./AuthContext";
import formatCurrency from "./utility/formatCurrency";
import Button from "./components/Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ARImageViewer from "./components/ARImageViewer";
import { getColorName } from "./utility/colorNaming";
import {
  Home,
  Sofa,
  Bed,
  LampDesk,
  UtensilsCrossed,
  BookOpen,
  Users,
  Sparkles,
  Heart,
  Leaf,
  Frame,
  Truck,
  Banknote,
} from "lucide-react";
import ArtGallery from "./components/ArtGallery/ArtGallery";

const roomIcons = {
  "Living Room": Home,
  "Family Room": Sofa,
  Bedroom: Bed,
  "Home Office": LampDesk,
  Kitchen: UtensilsCrossed,
  Study: BookOpen,
  "Dining Room": Users,
  Hallway: Sparkles,
  Nursery: Heart,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GeneratedPrint = () => {
  const { session } = useContext(AuthContext);
  const { id } = useParams();
  const { addToBasket } = useBasket();
  const navigate = useNavigate();
  const [printData, setPrintData] = useState(null);
  const [printFormats, setPrintFormats] = useState([]);
  const [printSizes, setPrintSizes] = useState([]);
  const [printExtras, setPrintExtras] = useState([]);
  const [printFrames, setPrintFrames] = useState([]);
  const [price, setPrice] = useState(0);
  const [variantDetails, setVariantDetails] = useState(null);
  const [addingToBasket, setAddingToBasket] = useState(false);
  const [showAR, setShowAR] = useState(false);
  const [showFullPrompt, setShowFullPrompt] = useState(false);
  const [error, setError] = useState(false);

  const [userSelection, setUserSelection] = useState({
    format: 2,
    formatTitle: "Print Only",
    size: null,
    sizeTitle: null,
  });

  const fetchVariantDetails = useCallback(async () => {
    if (!userSelection.format) {
      return;
    }

    try {
      let whereClause = {
        product_id: "990f90ef-ec50-4bf2-8971-85ee065801f3",
        format_option_id: userSelection.format,
      };

      // Include size in the where clause if format is not digital
      if (userSelection.format !== "digital") {
        whereClause = {
          ...whereClause,
          ...(userSelection.size && { size_option_id: userSelection.size }),
        };
      }

      const variantData = await fetchData(
        "product_variants",
        "*, products (id, friendly_name)",
        whereClause
      );

      if (variantData.length > 0) {
        // Find the correct variant based on extras selection
        const selectedVariant = variantData.find(
          (variant) =>
            userSelection.extras
              ? variant.extra_option_id === userSelection.extras // Match the selected extra
              : variant.extra_option_id === null // Get variant with no extras
        );

        // If we found a matching variant, use it; otherwise log an error
        if (selectedVariant) {
          setVariantDetails(selectedVariant);
        } else {
          console.error("No matching variant found for the selected options");
        }
      } else {
        console.error("No variants found for the selected options");
      }
    } catch (error) {
      console.error("Error fetching variant:", error);
    }
  }, [userSelection]);

  useEffect(() => {
    fetchVariantDetails();
  }, [userSelection, fetchVariantDetails]);

  useEffect(() => {
    if (printData && session) {
      const isOwner = session?.user?.id === printData.user_id;
      console.log("User owns this print:", isOwner);
    }
  }, [printData, session]);

  const fetchPrintData = useCallback(async () => {
    try {
      const data = await fetchData(
        "generation_jobs",
        `
          id, 
          title, 
          original_prompt, 
          revised_prompt, 
          image_paths, 
          user_id, 
          keywords, 
          color_category, 
          color_psychology_impact_level, 
          color_psychology_primary_trait, 
          recommended_room, 
          color_palette, 
          description, 
          dominant_color, 
          vibrant_color,
          prompt_size,
          prompt_sizes (
            id,
            value
          )
        `,
        { id: id }
      );
      if (data.length > 0) {
        setPrintData(data[0]);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching generation jobs: ", error);
      setError(true);
    }
  }, [id]);

  const fetchPrintOptions = useCallback(async () => {
    try {
      const optionsData = await fetchData("product_options", "*");

      // Filter options by their type
      const printFormatsData = optionsData.filter(
        (option) => option.type === "Format"
      );
      const printSizesData = optionsData.filter(
        (option) => option.type === "Size"
      );
      const printExtrasData = optionsData.filter(
        (option) => option.type === "Extras"
      );
      const printFramesData = optionsData.filter(
        (option) => option.type === "Frame"
      );

      setPrintFormats(printFormatsData);
      setPrintSizes(printSizesData);
      setPrintExtras(printExtrasData);
      setPrintFrames(printFramesData);

      // Set initial size based on orientation
      if (printData?.prompt_sizes) {
        const orientation = getOrientation(printData.prompt_sizes.value);
        const availableSizes = filterSizesByOrientation(
          printSizesData,
          orientation
        );

        if (availableSizes.length > 0) {
          const defaultSize =
            availableSizes.find((size) => size.default) || availableSizes[0];
          setUserSelection((current) => ({
            ...current,
            size: defaultSize.id,
            sizeTitle: defaultSize.value,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching print options: ", error);
      setError(true);
    }
  }, [printData]);

  useEffect(() => {
    fetchVariantDetails();
  }, [userSelection, fetchVariantDetails]);

  const handleFormatChange = useCallback(
    (item) => {
      setUserSelection((currentSelection) => {
        let newSelection = {
          ...currentSelection,
          format: item.id,
          formatTitle: item.value,
        };

        if (item.value === "Digital") {
          newSelection.size = null;
          newSelection.extras = null;
          newSelection.sizeTitle = null;
        } else {
          // For Framed or Print Only formats
          if (printData?.prompt_sizes) {
            const orientation = getOrientation(printData.prompt_sizes.value);
            const availableSizes = filterSizesByOrientation(
              printSizes,
              orientation
            );

            if (availableSizes.length > 0) {
              const defaultSize =
                availableSizes.find((size) => size.default) ||
                availableSizes[0];
              newSelection.size = defaultSize.id;
              newSelection.sizeTitle = defaultSize.value;
            }
          }
        }

        return newSelection;
      });
    },
    [printData?.prompt_sizes, printSizes]
  );

  const handleSizeChange = useCallback((item) => {
    setUserSelection((currentSelection) => {
      const newSelection = {
        ...currentSelection,
        size: item.id,
        sizeTitle: item.value,
        sizePrice: item.base_price,
      };

      return newSelection;
    });
  }, []);

  const handleExtrasChange = useCallback((item) => {
    setUserSelection((currentSelection) => {
      const newSelection = {
        ...currentSelection,
        extras: item.id,
        extrasTitle: item.value,
        extrasPrice: item.base_price || 0,
      };

      return newSelection;
    });
  }, []);

  const handleFrameChange = useCallback((item) => {
    setUserSelection((currentSelection) => {
      return { ...currentSelection, frame: item.id, frameTitle: item.value };
    });
  }, []);

  useEffect(() => {
    if (variantDetails) {
      setPrice(variantDetails.price);
    }
  }, [variantDetails]);

  const handleAddToBasket = async () => {
    setAddingToBasket(true);
    if (!variantDetails) {
      console.error("Variant details not found");
      return;
    }

    const newItem = {
      id: `${printData.id}/${variantDetails.id}`,
      variantId: variantDetails.id,
      generationJobId: printData.id,
      productId: variantDetails.products.id,
      sku: variantDetails.sku,
      price: variantDetails.price,
      title: `${printData.title} - ${variantDetails.products.friendly_name} - ${
        userSelection.formatTitle
      } ${userSelection.sizeTitle ? `- ${userSelection.sizeTitle}` : ""} ${
        userSelection.extrasTitle ? `- ${userSelection.extrasTitle}` : ""
      }`,
      image: printData.image_paths[0],
      quantity: 1,
    };

    try {
      await addToBasket(newItem);
    } catch (error) {
      console.error("There was an error adding the item to the basket.", error);
    } finally {
      setTimeout(() => setAddingToBasket(false), 1000);
    }
  };

  // Handle escape key for AR viewer
  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        setShowAR(false);
      }
    };

    if (showAR) {
      window.addEventListener("keydown", handleEscKey);
      return () => window.removeEventListener("keydown", handleEscKey);
    }
  }, [showAR]);

  // Initial data fetching
  useEffect(() => {
    fetchPrintData();
  }, [id, fetchPrintData]);

  useEffect(() => {
    fetchPrintOptions();
  }, [fetchPrintOptions]);

  const additionalInfo = [
    { label: "Recommended Room", key: "recommended_room" },
    { label: "Color Category", key: "color_category" },
    { label: "Impact Level", key: "color_psychology_impact_level" },
    { label: "Primary Trait", key: "color_psychology_primary_trait" },
    { label: "Dominant Color", key: "dominant_color", isColor: true },
    { label: "Vibrant Color", key: "vibrant_color", isColor: true },
  ];

  // Update the handleColorClick to properly parse color values
  const handleColorClick = (color) => {
    let rgbValues;

    // Handle both array and string formats
    if (Array.isArray(color)) {
      rgbValues = normalizeColorArray(color);
    } else if (typeof color === "string") {
      try {
        rgbValues = JSON.parse(color).map(Number);
      } catch (e) {
        console.error("Error parsing color:", e);
        return;
      }
    } else {
      console.error("Invalid color format:", color);
      return;
    }

    const colorName = getColorName(rgbValues);
    navigate(
      `/collections/color/${encodeURIComponent(colorName.toLowerCase())}`
    );
  };

  // Helper function to convert array values to numbers
  const normalizeColorArray = (arr) => {
    if (!Array.isArray(arr)) return [0, 0, 0];
    return arr.map((val) => Number(val));
  };

  const parseColorArray = (colorString) => {
    if (!colorString) return [0, 0, 0];
    try {
      if (Array.isArray(colorString)) {
        return colorString.map(Number);
      }
      return JSON.parse(colorString).map(Number);
    } catch (e) {
      console.error("Error parsing color:", e);
      return [0, 0, 0];
    }
  };

  // Helper function to safely handle room data
  const parseRoomData = (roomData) => {
    if (Array.isArray(roomData)) {
      return roomData;
    }
    try {
      return JSON.parse(roomData);
    } catch (e) {
      console.error("Error parsing room data:", e);
      return [];
    }
  };

  // Update helper function to only distinguish between square and rectangular
  const getOrientation = (sizeString) => {
    const [width, height] = sizeString.split("x").map(Number);
    return width === height ? "square" : "rectangular";
  };

  // Update filter function to match new orientation categories
  const filterSizesByOrientation = (sizes, targetOrientation) => {
    return sizes.filter((size) => {
      const [width, height] = size.value.split("x").map(Number);
      const sizeOrientation = width === height ? "square" : "rectangular";
      return sizeOrientation === targetOrientation;
    });
  };

  // Function to generate structured data for the print
  const generateStructuredData = useCallback(() => {
    if (!printData || !price) return null;

    return {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: printData.title,
      description: printData.description || printData.revised_prompt,
      image: printData.image_paths,
      brand: {
        "@type": "Brand",
        name: "Create Prints",
      },
      offers: {
        "@type": "Offer",
        url: window.location.href,
        priceCurrency: "GBP",
        price: price,
        availability: "https://schema.org/InStock",
        priceValidUntil: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0],
        itemCondition: "https://schema.org/NewCondition",
      },
      category: printData.color_category,
      keywords: printData.keywords?.join(", "),
      color: printData.color_category,
      material: "High-quality print",
      additionalProperty: [
        {
          "@type": "PropertyValue",
          name: "recommendedRoom",
          value: Array.isArray(printData.recommended_room)
            ? printData.recommended_room.join(", ")
            : printData.recommended_room,
        },
        {
          "@type": "PropertyValue",
          name: "colorPsychologyTrait",
          value: printData.color_psychology_primary_trait,
        },
        {
          "@type": "PropertyValue",
          name: "impactLevel",
          value: printData.color_psychology_impact_level,
        },
      ],
    };
  }, [printData, price]);

  // Generate breadcrumb structured data
  const generateBreadcrumbData = useCallback(() => {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: window.location.origin,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Art Prints",
          item: `${window.location.origin}/collections`,
        },
        {
          "@type": "ListItem",
          position: 3,
          name: printData?.color_category || "Print",
          item: `${
            window.location.origin
          }/collections/color-category/${printData?.color_category
            ?.toLowerCase()
            .replace(/\s+/g, "-")}`,
        },
        {
          "@type": "ListItem",
          position: 4,
          name: printData?.title || "Generated Print",
          item: window.location.href,
        },
      ],
    };
  }, [printData]);

  if (error) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-semibold text-gray-900">
          Error loading print
        </h2>
        <p className="mt-2 text-gray-600">
          There was an error loading this print. Please try again later.
        </p>
      </div>
    );
  }

  return (
    <div>
      {printData && (
        <Helmet>
          <title>{`${
            printData.title || "Generated Print"
          } - Printed Wall Art | Create Prints`}</title>
          <meta
            name="description"
            content={`${
              printData.description || printData.revised_prompt
            } Perfect for ${
              Array.isArray(printData.recommended_room)
                ? printData.recommended_room.join(", ")
                : printData.recommended_room
            }. ${printData.color_psychology_primary_trait} wall art print.`}
          />

          {/* Open Graph tags */}
          <meta
            property="og:title"
            content={`${
              printData.title || "Generated Print"
            } - Wall Art | Create Prints`}
          />
          <meta
            property="og:description"
            content={printData.description || printData.revised_prompt}
          />
          <meta property="og:image" content={printData.image_paths[0]} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="product" />
          <meta property="og:site_name" content="Create Prints" />
          <meta property="og:price:amount" content={price} />
          <meta property="og:price:currency" content="GBP" />
          <meta property="og:availability" content="instock" />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${printData.title || "Generated Print"} - Wall Art`}
          />
          <meta
            name="twitter:description"
            content={printData.description || printData.revised_prompt}
          />
          <meta name="twitter:image" content={printData.image_paths[0]} />

          {/* Product specific meta tags */}
          <meta name="product:price:amount" content={price} />
          <meta name="product:price:currency" content="GBP" />
          <meta name="product:availability" content="in stock" />
          <meta name="product:condition" content="new" />
          <meta name="product:brand" content="Create Prints" />
          <meta name="product:category" content={printData.color_category} />
          <meta name="product:color" content={printData.color_category} />
          <meta
            name="product:target_room"
            content={
              Array.isArray(printData.recommended_room)
                ? printData.recommended_room.join(", ")
                : printData.recommended_room
            }
          />

          {/* Keywords */}
          {printData && (
            <meta
              name="keywords"
              content={[
                ...(printData.keywords || []),
                ...(Array.isArray(printData.recommended_room)
                  ? printData.recommended_room
                  : [printData.recommended_room]),
                printData.color_psychology_primary_trait,
                printData.color_psychology_impact_level,
                printData.color_category,
                "wall art",
                "art print",
                "printed poster",
                "home decor",
                "wall decor",
                "interior design",
                "room decoration",
                "art prints uk",
                "modern wall art",
                "contemporary art",
                "affordable art",
                "custom prints",
                "ai generated art",
                "unique wall art",
                "art for sale",
                `${printData.color_category} wall art`,
                `${printData.color_category} prints`,
                `${printData.color_psychology_primary_trait} art`,
                ...(Array.isArray(printData.recommended_room)
                  ? printData.recommended_room.map((room) => `${room} wall art`)
                  : [`${printData.recommended_room} wall art`]),
              ]
                .filter(Boolean)
                .join(", ")}
            />
          )}

          {/* Canonical URL */}
          <link rel="canonical" href={window.location.href} />

          {/* JSON-LD structured data */}
          {generateStructuredData() && (
            <script type="application/ld+json">
              {JSON.stringify(generateStructuredData())}
            </script>
          )}

          {/* Breadcrumb structured data */}
          <script type="application/ld+json">
            {JSON.stringify(generateBreadcrumbData())}
          </script>
        </Helmet>
      )}

      <div className="mx-auto max-w-2xl px-4 lg:py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {printData ? (
          <>
            <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-14">
              {/* Left side - Image gallery OR AR viewer */}
              <div className="relative">
                {showAR ? (
                  <div className="relative">
                    <button
                      onClick={() => setShowAR(false)}
                      className="absolute top-4 right-4 z-10 p-2 bg-white rounded-full shadow-md hover:bg-gray-100"
                    >
                      <XMarkIcon className="h-6 w-6" />
                    </button>
                    <div className="w-full">
                      <ARImageViewer imageUrl={printData.image_paths[0]} />
                    </div>
                  </div>
                ) : (
                  <>
                    <Tab.Group as="div" className="flex flex-col-reverse">
                      {/* Image selector */}
                      <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
                        <Tab.List className="grid grid-cols-4 gap-6">
                          {printData.image_paths.map((image, index) => (
                            <Tab
                              key={index}
                              className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                            >
                              {({ selected }) => (
                                <>
                                  <span className="sr-only"></span>
                                  <span className="absolute inset-0 overflow-hidden rounded-md">
                                    <img
                                      src={image}
                                      alt={`${printData.title} - Thumbnail ${
                                        index + 1
                                      }`}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </span>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "ring-indigo-500"
                                        : "ring-transparent",
                                      "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </Tab>
                          ))}
                        </Tab.List>
                      </div>

                      <Tab.Panels className=" w-full">
                        {printData.image_paths.map((image, index) => (
                          <Tab.Panel key={index}>
                            <img
                              src={image}
                              alt={`${printData.title} - View ${index + 1}`}
                              className="h-full w-full object-cover object-center sm:rounded-lg"
                            />
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>
                    <Button
                      onClick={() => setShowAR(true)}
                      className="w-full mt-6 bg-white !text-slate-900"
                      variant="secondary"
                    >
                      View in 3D
                    </Button>
                  </>
                )}
              </div>

              {/* Right side - Product info */}
              <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                  {printData.title ? printData.title : "Your Generated Print"}
                </h1>

                <div className="mt-4 mb-6">
                  <p className="text-3xl tracking-tight text-gray-900 font-semibold">
                    {formatCurrency(price)}
                  </p>
                </div>

                <div className="mt-3">
                  <h2 className="sr-only">Product information</h2>
                </div>

                <div>
                  <Cards
                    data={printFormats}
                    label="Format"
                    onChange={handleFormatChange}
                    defaultSelection={userSelection.format}
                  />
                </div>

                {userSelection.format !== 1 && (
                  <div className="mt-6">
                    <Cards
                      data={
                        printData?.prompt_sizes
                          ? filterSizesByOrientation(
                              printSizes,
                              getOrientation(printData.prompt_sizes.value)
                            )
                          : printSizes
                      }
                      label="Size"
                      onChange={handleSizeChange}
                      defaultSelection={userSelection.size}
                    />
                  </div>
                )}

                {userSelection.format === 3 && (
                  <div className="mt-6">
                    <Cards
                      data={printFrames}
                      label="Frame"
                      onChange={handleFrameChange}
                      defaultSelection={userSelection.frame}
                    />
                  </div>
                )}

                {userSelection.format === 3 && (
                  <div className="mt-6">
                    <Cards
                      data={[
                        ...printExtras,
                        {
                          id: null,
                          value: "No mounting",
                        },
                      ]}
                      label="Extras"
                      onChange={handleExtrasChange}
                      defaultSelection={userSelection.extras}
                    />
                  </div>
                )}

                <div className="my-10">
                  <Button
                    onClick={handleAddToBasket}
                    className="w-full text-xl font-medium py-4"
                    disabled={addingToBasket}
                    loading={addingToBasket}
                  >
                    {addingToBasket ? "Adding to bag" : "Add to bag"}
                  </Button>

                  {/* Create Your Own Print Section */}
                  <div className="mt-6 bg-white rounded-[26px] p-[2px] shadow-sm relative bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                    <div className="bg-white rounded-[24px] p-6">
                      <h3 className="font-semibold text-lg text-slate-900 mb-2">
                        🎵 Still haven't found, the wall art you were looking for?
                      </h3>
                      <p className="text-slate-700 mb-4">
                      Looking for something else? Turn your own words or photos into unique art prints, that magically match the style of your interior or existing art.
                      </p>
                      <Button url="/generate-print" className="bg-white !text-slate-900 border-2 border-slate-900 hover:!text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          className="h-4 w-4 mr-2"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Create your own print
                      </Button>
                    </div>
                  </div>

                  {/* Description Section */}
                  <div className="mt-8">
                    <h3 className="font-bold mb-2 text-lg">Description</h3>
                    <p className="text-base text-gray-700">
                      {printData.description || printData.revised_prompt}
                    </p>
                  </div>

                  <div className="mt-8 bg-gray-50 rounded-xl p-4 shadow-md">
                    <div className="flex items-center gap-2 mb-3">
                      <h3 className="font-bold mb-2 text-lg">
                        Reasons to buy from Create Prints
                      </h3>
                    </div>
                    <ul>
                      <li className="flex flex-row mb-5">
                        <Leaf className="h-6 w-6 mr-4" /> Printed on high
                        quality 180gsm eco-friendly paper
                      </li>
                      <li className="flex flex-row mb-5">
                        <Frame className="h-6 w-6 mr-4" />
                        Hand framed. If you choose the framed option, your wall
                        art will arrive framed and ready to hang.
                      </li>
                      <li className="flex flex-row mb-5">
                        <Truck className="h-6 w-6 mr-4" />
                        Printed and dispatched from the UK
                      </li>
                      <li className="flex flex-row">
                        <Banknote className="h-6 w-6 mr-4" />
                        30 Day money back guarantee
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="my-6">
                  <h3 className="font-bold mb-2 text-lg">Original Prompt</h3>
                  <div className="text-sm text-gray-700">
                    {showFullPrompt ? (
                      <p>{printData.original_prompt}</p>
                    ) : (
                      <p>
                        {printData.original_prompt
                          .split("\n")
                          .slice(0, 1)
                          .join("\n")}
                        {printData.original_prompt.split("\n").length > 1 &&
                          "..."}
                      </p>
                    )}
                    {printData.original_prompt.split("\n").length > 3 && (
                      <button
                        onClick={() => setShowFullPrompt(!showFullPrompt)}
                        className="text-blue-600 hover:text-blue-800 mt-2"
                      >
                        {showFullPrompt ? "Show less" : "Show more"}
                      </button>
                    )}
                  </div>
                </div>

                <div className="mt-8">
                  <h3 className="text-xl font-semibold mb-2">
                    Additional Information
                  </h3>
                  <p className="mb-4 text-sm text-gray-700">
                    Every piece of wall art that is created on Create Prints is
                    analysed using our own algorithm to determine which space it's best suited to and how it will impact you.{" "}
                  </p>

                  {/* Add Color Palette Section */}
                  {printData?.color_palette && (
                    <div className="mb-6">
                      <span className="font-medium block mb-3">
                        Color Palette:
                      </span>
                      <div className="flex flex-wrap gap-4">
                        {printData.color_palette.map((color, index) => {
                          // Normalize all color values to numbers
                          const rgbValues = normalizeColorArray(color);
                          const dominantColor = normalizeColorArray(
                            printData.dominant_color
                          );
                          const vibrantColor = normalizeColorArray(
                            printData.vibrant_color
                          );

                          const hexColor = rgbToHex(...rgbValues);
                          const colorName = getColorName(rgbValues);

                          // Simple array comparison
                          const isDominant = rgbValues.every(
                            (val, i) => val === dominantColor[i]
                          );
                          const isVibrant = rgbValues.every(
                            (val, i) => val === vibrantColor[i]
                          );

                          return (
                            <button
                              key={index}
                              onClick={() => handleColorClick(color)}
                              className="flex flex-col items-center group relative"
                            >
                              <div
                                className={`w-12 h-12 rounded-full border-2 transition-transform group-hover:scale-110 ${
                                  isDominant || isVibrant
                                    ? "border-blue-500 ring-2 ring-blue-300"
                                    : "border-gray-200"
                                }`}
                                style={{
                                  backgroundColor: hexColor,
                                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                }}
                              />
                              <span className="mt-2 text-sm text-gray-600 group-hover:text-blue-600">
                                {colorName}
                                {isDominant && " (Dominant)"}
                                {isVibrant && " (Vibrant)"}
                              </span>
                              <span className="mt-1 text-xs text-gray-400">
                                {hexColor.toUpperCase()}
                              </span>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div className="space-y-2">
                    {printData.keywords && printData.keywords.length > 0 && (
                      <div className="flex flex-col sm:flex-row sm:items-center">
                        <span className="font-medium mr-2">Keywords:</span>
                        <div className="flex flex-wrap gap-2">
                          {printData.keywords.map((keyword, index) => (
                            <Link
                              key={index}
                              to={`/collections/keywords/${encodeURIComponent(
                                keyword.toLowerCase().replace(/\s+/g, "-")
                              )}`}
                              className="text-blue-600 hover:underline"
                            >
                              {keyword}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                    {additionalInfo.map(({ label, key, isColor }) => (
                      <div
                        key={key}
                        className={classNames(
                          key === "recommended_room"
                            ? "flex flex-col gap-3"
                            : "flex flex-col sm:flex-row sm:items-center"
                        )}
                      >
                        <span
                          className={classNames(
                            "font-medium",
                            key !== "recommended_room" && "mr-2"
                          )}
                        >
                          {label}
                        </span>
                        <div
                          className={classNames(
                            "flex flex-wrap",
                            key === "recommended_room" ? "gap-4 mb-4" : "gap-2"
                          )}
                        >
                          {printData[key] &&
                            (isColor ? (
                              <button
                                onClick={() => handleColorClick(printData[key])}
                                className="flex items-center hover:text-blue-600"
                              >
                                <div
                                  className="w-4 h-4 rounded-full mr-2"
                                  style={{
                                    backgroundColor: rgbToHex(
                                      ...parseColorArray(printData[key])
                                    ),
                                    border: "1px solid rgba(0,0,0,0.1)",
                                  }}
                                />
                                {getColorName(parseColorArray(printData[key]))}
                              </button>
                            ) : key === "recommended_room" ? (
                              parseRoomData(printData[key]).map(
                                (room, index) => {
                                  const IconComponent = roomIcons[room];
                                  return (
                                    <div
                                      key={index}
                                      className="flex flex-col items-center gap-2 group"
                                    >
                                      <div className="w-12 h-12 bg-white rounded-full border-2 transition-transform group-hover:scale-110 border-gray-200 flex items-center justify-center">
                                        {IconComponent && (
                                          <IconComponent
                                            className="h-6 w-6"
                                            strokeWidth={1.5}
                                          />
                                        )}
                                      </div>
                                      <Link
                                        to={`/collections/${key.replace(
                                          /_/g,
                                          "-"
                                        )}/${encodeURIComponent(
                                          room
                                            .toLowerCase()
                                            .replace(/\s+/g, "-")
                                        )}`}
                                        className="flex items-center gap-2 text-blue-600"
                                      >
                                        <span className="text-sm text-gray-600 hover:text-blue-600">
                                          {room}
                                        </span>
                                      </Link>
                                    </div>
                                  );
                                }
                              )
                            ) : typeof printData[key] === "string" &&
                              printData[key] !== "Undefined" ? (
                              <Link
                                to={`/collections/${key.replace(
                                  /_/g,
                                  "-"
                                )}/${encodeURIComponent(
                                  printData[key]
                                    .toLowerCase()
                                    .replace(/\s+/g, "-")
                                )}`}
                                className="text-blue-600 hover:underline"
                              >
                                {printData[key]}
                              </Link>
                            ) : (
                              <span className="text-gray-400">Unknown</span>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Related Prints Section */}
            <section className="mt-12">
              <h2 className="text-2xl font-semibold mb-6">Related Prints</h2>
              <ArtGallery
                relatedTo={printData}
                resultsPerPage={8}
                className="mb-8"
              />
            </section>
          </>
        ) : (
          <div>Loading</div>
        )}
      </div>
    </div>
  );
};

// Update the rgbToHex function to handle string values
const rgbToHex = (r, g, b) => {
  const toHex = (c) => {
    const hex = parseInt(c).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export default GeneratedPrint;
