import { useState, useEffect } from 'react';
import { supabase } from '../supabase.js';

export function useCollectionMetadata(collectionType = null, value = null) {
  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchMetadata() {
      try {
        if (!collectionType || !value) {
          setMetadata(null);
          return;
        }

        const { data, error } = await supabase
          .from('collection_metadata')
          .select('*')
          .eq('collection_type', collectionType)
          .ilike('value', value)
          .single();

        if (error) throw error;
        setMetadata(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchMetadata();
  }, [collectionType, value]);

  return { metadata, loading, error };
}

export function useCollectionsByType(collectionType = null) {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCollections() {
      try {
        let query = supabase
          .from('collection_metadata')
          .select('*')
          .neq('value', 'Undefined')
          .neq('value', 'undefined');

        if (collectionType) {
          query = query.eq('collection_type', collectionType);
        }

        // Order by title for consistent display
        query = query.order('title');

        const { data, error } = await query;

        if (error) throw error;
        setCollections(data || []);
      } catch (err) {
        setError(err.message);
        setCollections([]);
      } finally {
        setLoading(false);
      }
    }

    fetchCollections();
  }, [collectionType]);

  return { collections, loading, error };
} 